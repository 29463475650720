import React from 'react';

import { Headline, Text, InfoPageContainer } from '../components';

import { imprintContent } from '../data';

const Imprint = () => (
  <InfoPageContainer>
    <Headline>Imprint/Impressum</Headline>
    {imprintContent.map((paragraph, index) => (
      <Text key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
    ))}
  </InfoPageContainer>
);

export default Imprint;
